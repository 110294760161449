import React from 'react';
import ReactDOM from 'react-dom/client';
import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import './index.css';
import Layout from './components/layout/Layout';
import reportWebVitals from './reportWebVitals';
import authReducer from './store/reducers/auth'
import profileReducer from "./store/reducers/profile"
import reportReducer from "./store/reducers/reports"
import * as actionTypes from "./store/actions/actionTypes"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appReducer = combineReducers({
    auth: authReducer,
    profile: profileReducer,
    reports: reportReducer
});

const rootReducer = (state, action) => {
  // alert(action.type)
  if (action.type === actionTypes.REPORT_FLUSH) {
      return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <Layout />
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
