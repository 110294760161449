import { HiViewGridAdd } from 'react-icons/hi';
import { MdOutlineMiscellaneousServices, MdDeviceHub } from 'react-icons/md'
import { AiOutlineUser } from 'react-icons/ai'
import { CgPushChevronUpR } from "react-icons/cg"
import { FiLogOut } from "react-icons/fi"

const sidebarObject = [
    {
        "display_name": "Create",
        "route": "/",
        "icon": <HiViewGridAdd size="25" />,
        "visible": "admin"
    },
    {
        "display_name": "Client Data",
        "route": "/clientData",
        "icon": <AiOutlineUser size="25" />,
        "visible": "admin"
    },
    {
        "display_name": "Device Data",
        "route": "/deviceData",
        "icon": <MdDeviceHub size="25" />,
        "visible": "admin"
    },
    {
        "display_name": "Services",
        "route": "/services",
        "icon": <MdOutlineMiscellaneousServices size="25" />,
        "visible": "su"
    },
    // {
    //     "display_name": "Push Data",
    //     "route": "/pushData",
    //     "icon": <CgPushChevronUpR />
    // },
    {
        "display_name": "Logout",
        "route": "/logout",
        "icon": <FiLogOut />
    }
]

export default sidebarObject;