import React from 'react'
import NavigationRoutes from "../../assets/sidebarObjects/Navigation"
import { NavLink } from "react-router-dom";
import "./sidebar.css"

import * as actions from "../../store/actions/index";
import { connect } from "react-redux";

const SidebarItem = (props) => {
    const active = props.active ? "active" : "";
    let isSuperUser = JSON.parse(localStorage.getItem('devices')).user_role === "su";
    return (
        (props.visible === 'su' && !isSuperUser) ? null : <li className={`list ${active}`} >
            <NavLink to={props.route} onClick={props.display_name === "Logout" && props.onclick}>
                <span className="icon">{props.icon}</span>
                <span className="title">{props.display_name}</span>
            </NavLink>
        </li>
    );
};


function Sidebar(props) {
    return (
        <div className="sidebar-wrapper">
            <div className="navigation" id="sidebar-nav">
                <ul>
                    {
                        NavigationRoutes.map((item, index) => {
                            return (<SidebarItem key={index} visible={item.visible} onclick={props.revokeAuthentication} route={item.route} display_name={item.display_name} icon={item.icon} />);
                        })
                    }
                </ul>
            </div>
        </div>
    )
}


const mapDispatchToProps = dispatch => {
    return { revokeAuthentication: () => dispatch(actions.checkAuthTimeout(10)) }
}

export default connect(null, mapDispatchToProps)(Sidebar);
