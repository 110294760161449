import React, { useEffect } from 'react'
import { HashRouter, Route, Routes } from "react-router-dom";
import Sidebar from "../sidebar/Sidebar"
import Dashboard from "../../pages/dashboard/Dashboard"
import Services from "../../pages/services/Services"
import Auth from "../../Containers/Auth/Auth"
import View from "../../pages/userData/View"
import DeviceData from "../../pages/deviceData/DeviceView"

import * as actions from "../../store/actions/index";
import { connect } from "react-redux";

// const Auth = (props) => {
//     console.log({ auth: props.auth });
//     const loginHandler = () => {
//         let id = document.getElementById('user').value;
//         let pass = document.getElementById('pass').value;
//         props.loginDetails({ id, pass });
//     }

//     return (
//         <div className="ee-card" style={style.cardStyle}>
//             <h4 >Thingzcloud- Admin Panel  </h4>
//             <h4 style={style.heading}>Login</h4>

//             <input id="user" style={style.inputStyle} type="text" placeholder="Enter your email/username" />
//             <input id="pass" style={style.inputStyle} type="password" placeholder="Enter your password" />
//             <div style={style.buttonConatainer}>
//                 <button style={style.buttonStyle} onClick={loginHandler}>LOGIN</button>
//             </div>
//         </div>
//     )
// }




function Layout(props) {
    let userRole;
    useEffect(() => {
        console.log(props);
        try {
            userRole = JSON.parse(localStorage.getItem('devices')).user_role;
            console.log(userRole)
            if (userRole === 'su') {
                document.documentElement.setAttribute("data-theme", `blue-dark`);
            }
            else {
                document.documentElement.setAttribute("data-theme", `blue-light`);
            }
        }
        catch (e) {
            console.log(e)
        }
    }, [props]);

    let authorized = localStorage.getItem('token') ? true : false;


    return (
        <HashRouter>
            {
                authorized ?
                    <>
                        <Sidebar />
                        <div className="content-wrapper">
                            <div className="content" id="subPages">
                                <Routes>
                                    <Route path="/" exact element={<Dashboard />} />
                                    <Route path="/clientData" element={<View />} />
                                    <Route path="/deviceData" element={<DeviceData />} />

                                    userRole === 'su' &&
                                    <Route path="/services" element={<Services />} />

                                    {/* <Route path="/logout" element={<Logout />} /> */}
                                </Routes>
                            </div>
                        </div>
                    </> : <Auth />
            }
        </HashRouter>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);



