import React, { Component } from 'react';
import "./MessageBox.scss"

class MessageBox extends Component {
    render() {

        let cssClass = null, cssStyle = null;

        if (this.props.type === 'success') {
            cssClass = "mb SuccessMsg animated flipInX";
        }
        if (this.props.type === 'info') {
            cssClass = "mb InfoMsg animated flipInX";
        }
        if (this.props.type === 'error') {
            cssClass = "mb ErrorMsg animated flipInX";
        }

        setTimeout(() => { cssStyle = { display: "none" } }, 2000);

        return (
            <>
                <p className={cssClass} style={cssStyle}>{this.props.msg}</p>
            </>
        );
    }
}

export default MessageBox;