import React from 'react'
import { BiAddToQueue } from "react-icons/bi"
import { Button, Row, Col } from 'react-bootstrap'
import { useState, useEffect } from "react"
import axios from 'axios';

import Card from "../../components/Card/Card"
import MessageBox from "../../components/BasicMessageBox/MessageBox"
import 'bootstrap/dist/css/bootstrap.min.css';
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}
export const Dashboard = (props) => {
    setInterval(() => { props.checkAuthStatus() }, 10000);
    let userRole = JSON.parse(localStorage.getItem('devices')).user_role;
    const style = {
        select: { backgroundColor: "var(--theme-color)", padding: "8px 10px", borderRadius: "5px", border: "none", marginBottom: "10px" },
        option: {
            margin: "5px",
            padding: "5px"
        },
        textBox: {
            backgroundColor: "var(--theme-color-sec)",
            border: "none",
            borderRadius: "5px",
            outline: "none",
            margin: "2px",
            padding: "5px",
            fontSize: "12px",
            width: "30%",
            color: "var(--text-color)"
        }
    }




    console.log("dashbaords");
    const [DeviceState, setDeviceState] = useState(null);
    const [devDesc, setDevDesc] = useState("");
    const [devName, setDevName] = useState("");
    const [para, setPara] = useState(null);
    const [selectedDevPara, setSelectedDevPara] = useState(null);
    const [addDevType, setAddDevType] = useState(false);
    const [addDevPara, setAddDevPara] = useState(false);
    const [idInfo, setIdInfo] = useState(null);
    const [showMessage, setShowMessage] = useState(false);
    // const target = useRef(null);

    let addParaView = true;

    let tempPara = [];

    useEffect(() => {
        console.log("useEffect")
        let arr;
        getDeviceType().then(res => {
            arr = res;
            setDeviceState(arr);
            console.log(DeviceState);
            devTypeHandler();
        }).catch(err => {
            console.log(err)
        });
    }, []);



    const getDeviceType = async () => {
        return axios({
            url: `${process.env.REACT_APP_BASE_URL}/devices/getDeviceTypes`,
            method: 'get',
            headers: {
                'x-api-key': localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then(resp => {
            // console.log(resp.data)
            return resp.data;
            // setDeviceState({ dev: resp.data });
        }).catch(err => {
            let msg = err.response.data.message;
            let type = "error";
            setShowMessage({ msg, type });
            setTimeout(() => { setShowMessage(null) }, 2000);
            props.checkAuthStatus();
        })
    }

    const getDevPara = async (name) => {
        return axios({
            url: `${process.env.REACT_APP_BASE_URL}/devices/parameters/${name}`,
            method: 'get',
            headers: {
                'x-api-key': localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then(resp => {
            // console.log(resp.data)
            return resp.data;
            // setDeviceState({ dev: resp.data });
        }).catch(err => {
            return null;
        })
    }

    const addNewDevHandler = async () => {
        let name = document.getElementById("addDevName").value;
        let desc = document.getElementById("addDevDesc").value;
        return axios({
            url: `${process.env.REACT_APP_BASE_URL}/devices/addDeviceTypes`,
            method: 'post',
            headers: {
                'x-api-key': localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            data: {
                device_name: name,
                device_desc: desc
            }
        }).then(resp => {
            console.log(resp.data);
            let msg = resp.data.message;
            let type = "success";
            setShowMessage({ msg, type });
            setTimeout(() => { setShowMessage(null) }, 2000);
            let arr;
            getDeviceType().then(res => {
                arr = res;
                setDeviceState(arr);
                console.log(DeviceState);
                devTypeHandler();
            }).catch(err => {
                console.log(err)
            });
            return resp.data;
            // setDeviceState({ dev: resp.data });
        })
            .catch(err => {
                console.log(err.response.data.message);
                let msg = err.response.data.message;
                let type = "error";
                setShowMessage({ msg, type });
                setTimeout(() => { setShowMessage(null) }, 2000);
            })
    }

    const addNewParaHandler = async () => {
        let name = document.getElementById("addParaName").value;
        let unit = document.getElementById("addParaUnit").value;
        return axios({
            url: `${process.env.REACT_APP_BASE_URL}/devices/parameters`,
            method: 'post',
            headers: {
                'x-api-key': localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            data: {
                device_name: devName,
                parameters: name,
                unit
            }
        }).then(resp => {
            console.log(resp.data);
            let msg = resp.data.message;
            let type = "success";
            setShowMessage({ msg, type });
            setTimeout(() => { setShowMessage(null) }, 2000);
            getDevPara(devName);
            setDevName(devName);
            devTypeHandler();
            return resp.data;
        }).catch(err => {
            console.log(err);
            let msg = err.response.data.error;
            let type = "error";
            setShowMessage({ msg, type });
            setTimeout(() => { setShowMessage(null) }, 2000);
        })
    }

    const removeThisParaHandler = (e) => {
        let thisPara = para;
        console.log(thisPara);
        thisPara = thisPara.filter(item => item != e.target.innerHTML)
        console.log(thisPara);
        setPara(thisPara);
    }

    const addParaHandler = (e) => {

        let val = (e.target.innerHTML)
        if (!para || !para.includes(e.target.innerHTML)) {
            let t = [...tempPara];
            console.log(para);
            para ? setPara([...para, val]) : setPara([val]);
        }
    }

    const devTypeHandler = () => {
        let selectedType = document.getElementById('devType').value;
        if (selectedType !== "add") {
            setAddDevType(false)
            let val = DeviceState.filter(item => item.device_name === selectedType);
            console.log(val);
            let name = val[0].device_name;
            setDevName(name);
            let desc = (val[0].device_desc);
            console.log(desc)
            setDevDesc(desc);

            setPara(null);
            setIdInfo(null);

            let paraInfo;
            getDevPara(name).then(resp => {
                paraInfo = resp;
                console.log(paraInfo);
                let para = [];
                if (paraInfo) {
                    Object.entries(paraInfo.parameters).map((item, key) => {
                        console.log(item[0]);
                        para.push(item[0]);
                    })
                    setSelectedDevPara(para);
                    para = [];
                }
                else {
                    setSelectedDevPara([]);

                }
            });

        }
        else {
            setAddDevType(true);
            setSelectedDevPara([]);
        }
    }

    const createDevHandler = () => {
        axios({
            url: `${process.env.REACT_APP_BASE_URL}/devices/addDevice`,
            method: 'post',
            headers: {
                'x-api-key': localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            data: {
                device_name: devName,
                parameters: para
            }
        }).then(resp => {
            console.log(resp.data);
            let idData = resp.data;
            // alert(idData.Success);
            delete idData.Success;

            Object.entries(idData).map((item, key) => {
                console.log(item)
            })
            setIdInfo(idData);

        })
    }

    const copyInfoToClipboardHandler = () => {

        let textToWrite = "", type = "info", msg = "Copy to Clipboard !";

        Object.entries(idInfo).map((item, key) => {
            textToWrite += `#define ${item[0].toUpperCase()} "${item[1]}"\n`
        })
        console.log(textToWrite);
        navigator.clipboard.writeText(textToWrite);
        setShowMessage({ msg, type });
        setTimeout(() => {
            setShowMessage(null);
        }, 2000)
    }


    return (
        <div
            className="justify-content-center mt-2"
            style={{
                color: "var(--theme-color)", textAlign: "left",
                margin: "40px 0"
            }}
        >
            {showMessage && <MessageBox type={showMessage.type} msg={showMessage.msg} />}
            <h1 style={{ marginLeft: "20px", marginBottom: "60px" }}>Create Device</h1>
            <Row className="mx-0">
                <Col md="auto">
                    <Card heading="Select Device Type">
                        <select id="devType" onChange={devTypeHandler} style={style.select}>
                            {/* <option value="-1" >Select Device Type</option> */}
                            {
                                DeviceState && DeviceState.map(item =>
                                    <option value={item.device_name} style={{ ...style.option }} key={item.device_code} >{item.device_code + " " + item.device_name}</option>
                                )
                            }
                            {userRole === "su" && <option value="add" style={{ backgroundColor: "var(--theme-color-sec)", color: "var(--text-color)" }} >➕ Add New</option>}
                        </select>
                        <br />
                        {
                            addDevType ?
                                <>
                                    <input id="addDevName" style={style.textBox} placeholder="Enter Name" />
                                    <input id="addDevDesc" style={style.textBox} placeholder="Enter Desc" />
                                    <Button variant="orange" style={{ margin: '5px', color: "var(--text-color)", backgroundColor: "var(--theme-color)", fontSize: "10px" }} onClick={addNewDevHandler}><BiAddToQueue /> Add</Button>
                                </> :
                                <p style={{ color: 'var(--text-color)' }}>{devDesc}</p>
                        }
                    </Card>
                </Col>
                <Col >
                    <Card heading="Select Device Parameters">
                        {

                            selectedDevPara && selectedDevPara.length > 0 ? selectedDevPara.map((item, key) =>
                                <Button key={item + key} onClick={addParaHandler} variant="orange" style={{ margin: '5px', color: "var(--theme-color)", borderColor: "var(--theme-color)", fontSize: "0.7rem" }}>{item}</Button>
                            ) : <code>No parameter found in this type. you can add new para by pressing '+' button below.</code>

                        }
                        {addDevPara ?
                            <>
                                <br />
                                <input id="addParaName" style={style.textBox} placeholder="Enter Para Name" />
                                <input id="addParaUnit" style={style.textBox} placeholder="Enter Para Unit" />
                                <Button variant="orange" style={{ margin: '5px', color: "var(--text-color)", backgroundColor: "var(--theme-color)", fontSize: "10px" }} onClick={addNewParaHandler}><BiAddToQueue /> Add</Button>
                            </> :
                            null}
                        <br />
                        {userRole === "su" && addParaView && <Button variant="dark" onClick={() => { setAddDevPara(!addDevPara) }} style={{ backgroundColor: "var(--theme-color-sec)", margin: '10px', fontSize: "0.6rem", transition: "all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)" }}>{!addDevPara ? "➕" : "❌"}</Button>}
                    </Card>
                </Col>
            </Row>

            {
                para && para.length > 0 &&
                <Row className="mx-0">
                    <Col md="auto">
                        <Card heading="Parameters">{para && para.map((item, key) =>
                            <Button key={item + key} onClick={removeThisParaHandler} variant="orange" style={{ margin: '5px', color: "var(--theme-color)", borderColor: "var(--theme-color)", fontSize: "0.7rem" }}>{item}</Button>
                        )}
                            <br />
                            <Button onClick={createDevHandler} style={{ backgroundColor: "var(--theme-color-sec)", color: "var(--text-color)", margin: '10px', fontSize: "0.6rem", transition: "all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)" }}>📱 Create</Button>
                        </Card>
                    </Col>

                    {idInfo && <Col>
                        <Card style={{ textAlign: "left" }} heading="Id Informations" icon="📋" iconEvent={copyInfoToClipboardHandler}>
                            <table style={{ maxHeight: "400px", overflow: "hidden", width: "auto" }}>
                                <tbody>
                                    {/* <tr><th>Name</th><th>Value</th></tr> */}
                                    {Object.entries(idInfo).map((item, key) => {
                                        return <tr><td style={{ color: "var(--theme-color)", fontSize: "0.7rem" }}>{item[0].replace("_", " ").toUpperCase()}</td><td style={{ fontSize: "1rem" }}><code>{item[1]}</code></td></tr>
                                    }
                                    )}
                                </tbody>
                            </table>
                        </Card>
                    </Col>
                    }
                </Row>
            }
        </div >
    )
}

const mapDispatchToProps = dispatch => {
    return { checkAuthStatus: () => dispatch(actions.authCheckState()) }
}

export default connect(null, mapDispatchToProps)(Dashboard);