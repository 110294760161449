import React from "react";
import "./card.css"
const card = props => {
  return (
    <div
      className="demo-card-wide  _card mdl-card neu-shadow"
      data-color={props.themecolor}
    >
      <div style={{ position: "relative" }}>
        {
          props.heading &&
          <h6 className="heading" style={{ display: "inline-block" }}>{props.heading}</h6>
        }
        {props.icon && <button onClick={props.iconEvent} style={{ position: "absolute", right: "0", backgroundColor: "transparent", border: "none", display: "inline-block" }} >{props.icon}</button>}
      </div>
      <div className="card-body">
        <div className="bg_image" card-image={props.cardImage}>
          {props.children}
        </div>
      </div>
      {/* <div className="swiper-slide"></div>
          <div className="swiper-slide"></div>
          <div className="swiper-slide"></div> */}
      {/* <div className="swiper-slide" style="background-image:url(./images/nature-5.jpg)"></div>
      <div className="swiper-slide" style="background-image:url(./images/nature-6.jpg)"></div>
      <div className="swiper-slide" style="background-image:url(./images/nature-7.jpg)"></div>
      <div className="swiper-slide" style="background-image:url(./images/nature-8.jpg)"></div>
      <div className="swiper-slide" style="background-image:url(./images/nature-9.jpg)"></div>
      <div className="swiper-slide" style="background-image:url(./images/nature-1.jpg0)"></div> */}
    </div>
  );
};

export default card;
