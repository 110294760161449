import React from 'react'
import { useState, useEffect } from "react"
import { Row, Col, Button, Form } from 'react-bootstrap'
import { connect } from "react-redux";
import axios from 'axios';
import { BiAddToQueue } from "react-icons/bi"

import Input from "../../components/UI/Input/Input"
import Card from "../../components/Card/Card"
import MessageBox from "../../components/BasicMessageBox/MessageBox"
import CreateTable from "../../components/CreateTable/CreateTable"
import 'bootstrap/dist/css/bootstrap.min.css';
import * as actions from "../../store/actions/index";
import "./Services.css"

const Services = (props) => {

    console.log("%crender", "color:#f00; font-size:30px");


    const [userData, setUserData] = useState(null);
    const [wordsArray, setWordsArray] = useState(null);
    const [showMessage, setShowMessage] = useState(false);
    const [addUser, setAddUser] = useState(false);
    const [addUserDev, setAddUserDev] = useState(false);
    const [updateService, setUpdateService] = useState(false);




    const style = {
        select: { backgroundColor: "var(--theme-color)", padding: "8px 10px", width: "auto", borderRadius: "5px", border: "none", marginBottom: "10px" },
        option: {
            margin: "5px",
            padding: "5px"
        },
    }


    let validateMsg = "";

    const checkValidateHandler = ({ ...args }) => {
        validateMsg = "";

        if (args.password !== args.cPassword) {
            validateMsg = "password and Confirm password must be the same"
            return false
        }
        else if (args.name.length < 1) {
            validateMsg = "Please Enter Name"
            return false
        }
        else if (args.email.length < 1) {
            validateMsg = "Please Enter Email"
            return false
        }
        else {
            validateMsg = "";
            return true;
        }
    }

    const changeServiceHandler = async (e) => {
        let device_id = e.target.id.substring(e.target.id.indexOf("_") + 1, e.target.id.lastIndexOf("_"));
        let email = document.getElementById('userServicesEmail').value;
        let extend_period = document.getElementById(`service_${device_id}_period`).value + " Year";

        console.log({ device_id, email, extend_period });
        try {
            const resp = await axios({
                url: `${process.env.REACT_APP_BASE_URL}/users/extend-service`,
                method: 'patch',
                headers: {
                    'x-api-key': localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                data: {
                    device_id, email, extend_period
                }
            });
            console.log(resp.data);
            let msg = resp.data.msg;
            let type = "success";
            setShowMessage({ msg, type });
            setTimeout(() => { setShowMessage(null); }, 2000);
            setTimeout(() => { getUserInfoHandler(); }, 2500);
        } catch (err) {
            console.log(err.response.data.error);
            let msg_1 = err.response.data.error;
            let type_1 = "error";
            setShowMessage({ msg_1, type_1 });
            setTimeout(() => { setShowMessage(null); }, 2000);
        }
    }


    const addUserDevHandler = async () => {
        let email = document.getElementById('userServicesEmail').value;
        let device_id = document.getElementById("addDevDevId").value;
        let serial_no = document.getElementById("addDevSno").value;

        console.log({
            email,
            device_id,
            serial_no,
        });
        return axios({
            url: `${process.env.REACT_APP_BASE_URL}/users/add-user-device`,
            method: 'patch',
            headers: {
                'x-api-key': localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            data: {
                email,
                device_id,
                serial_no,
            }
        }).then(resp => {
            console.log(resp.data);
            let msg = "Device Added Successfully!";
            let type = "success";
            setShowMessage({ msg, type });
            setTimeout(() => { setShowMessage(null) }, 2000);
            setTimeout(() => { getUserInfoHandler() }, 2500);


        }).catch(err => {
            console.log(err.response.data.error);
            let msg = err.response.data.error;
            let type = "error";
            setShowMessage({ msg, type });
            setTimeout(() => { setShowMessage(null) }, 2000);
        })
    }

    const getUsersOnChangeHandler = () => {
        let value = document.getElementById('userServicesEmail').value;
        // console.log(value);
        if (value !== "") {
            axios({
                url: `${process.env.REACT_APP_BASE_URL}/users/get-user-data/email/${value}`,
                method: 'get',
                headers: {
                    'x-api-key': localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                }
            }).then(resp => {
                let emails = [...resp.data.map(user => user.email)];
                console.log(emails);
                setWordsArray(emails)
                // console.log(wordsArray);
            }).catch(err => {
                console.log(err);
                props.checkAuthStatus();
            })
        }
    }



    const getUserInfoHandler = () => {

        setUpdateService(false);

        let value = document.getElementById('userServicesEmail').value;
        // console.log(value);
        axios({
            url: `${process.env.REACT_APP_BASE_URL}/users/get-user-data/email/${value}`,
            method: 'get',
            headers: {
                'x-api-key': localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then(resp => {
            console.log(resp.data[0]);
            setUserData(resp.data[0]);
            document.getElementById('userName').value = resp.data[0].name;
            document.getElementById('userFirmName').value = resp.data[0].firm_name;
            document.getElementById('userAddr').value = resp.data[0].address;
            document.getElementById('userContact').value = resp.data[0].phone;
            document.getElementById('userLat').value = resp.data[0].geo_coordinates[0];
            document.getElementById('userLong').value = resp.data[0].geo_coordinates[1];
            !resp.data[0].isVerifiedEmail ? document.getElementById('emailVerifiedNo').checked = true : document.getElementById('emailVerifiedYes').checked = true;
            !resp.data[0].isVerifiedPhone ? document.getElementById('phoneVerifiedNo').checked = true : document.getElementById('phoneVerifiedYes').checked = true;
            !resp.data[0].isRevoked.status ? document.getElementById('revokedNo').checked = true : document.getElementById('revokedYes').checked = true;
            document.getElementById('revokeReason').value = !resp.data[0].isRevoked.remark ? "" : resp.data[0].isRevoked.remark;

            console.log(resp.data[0].userRole);
            resp.data[0].userRole === "su" ? document.getElementById('userRole_su').checked = true :
                resp.data[0].userRole === "admin" ? document.getElementById('userRole_admin').checked = true : document.getElementById('userRole_client').checked = true;
        }).catch(err => {
            console.log(err);
            let msg = err.response.data.error;
            let type = "error";
            // console.log(msg);
            if (msg) {
                setShowMessage({ msg, type });
                setTimeout(() => setShowMessage(null), 2000)
            }
            setUserData(null);
            props.checkAuthStatus();
        })
    }


    const updateUserDataHandler = () => {
        let u_data = {};
        let name = document.getElementById('userName').value;
        let firm_name = document.getElementById('userFirmName').value;
        let address = document.getElementById('userAddr').value;
        let phone = document.getElementById('userContact').value;
        let lat = document.getElementById('userLat').value;
        let long = document.getElementById('userLong').value;
        let status = document.querySelector('input[name="revoke"]:checked').value;
        let isVerifiedEmail = document.querySelector('input[name="emailVerified"]:checked').value;
        let isVerifiedPhone = document.querySelector('input[name="phoneVerified"]:checked').value;
        let userRole = document.querySelector('input[name="userRole"]:checked').value;
        let remarks = document.getElementById('revokeReason').value;
        status = status === "true" ? true : false;
        isVerifiedEmail = isVerifiedEmail === "true" ? true : false;
        isVerifiedPhone = isVerifiedPhone === "true" ? true : false;
        u_data = { name, firm_name, address, phone, geo_coordinates: [lat, long], isRevoked: { status, remarks }, isVerifiedEmail, isVerifiedPhone, userRole };
        // setUserData({ ...userData, ...u_data });
        u_data = { ...userData, ...u_data }
        delete u_data.devices;
        console.log(u_data);

        axios({
            url: `${process.env.REACT_APP_BASE_URL}/users/update-user-data`,
            method: 'patch',
            headers: {
                'x-api-key': localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            data: { ...u_data }
        }).then(resp => {
            console.log(resp);
            let msg = resp.data.msg;
            let type = "success";
            setShowMessage({ msg, type });
            setTimeout(() => { setShowMessage(null) }, 2000)
        }).catch(err => {
            console.log(err);
        })
    }
    const updateUserDeviceHandler = () => {
        let email = document.getElementById('userServicesEmail').value;
        let devices = userData.devices.map(device => {
            return {
                device_id: document.getElementById(`${device.device_id}_id`).value,
                alias: document.getElementById(`${device.device_id}_alias`).value,
            }
        })
        console.log(devices)
        axios({
            url: `${process.env.REACT_APP_BASE_URL}/users/update-user-data`,
            method: 'patch',
            headers: {
                'x-api-key': localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            data: { email, devices }
        }).then(resp => {
            console.log(resp);
            let msg = resp.data.msg;
            let type = "success";
            setShowMessage({ msg, type });
            setTimeout(() => { setShowMessage(null) }, 2000)
        }).catch(err => {
            console.log(err);
            let msg = err.response.data.error;
            let type = "error";
            setShowMessage({ msg, type });
            setTimeout(() => { setShowMessage(null) }, 2000)
        })
    }
    const addUserDataHandler = () => {
        let u_data = {};
        let name = document.getElementById('addUserName').value;
        let email = document.getElementById('addUserEmail').value;
        let password = document.getElementById('addUserPassword').value;
        let cPassword = document.getElementById('addUserCPassword').value;

        u_data = { name, email, password, cPassword };
        if (!checkValidateHandler(u_data)) {
            document.querySelector('#validateMsg').innerHTML = validateMsg;
        }
        else {
            document.querySelector('#validateMsg').innerHTML = validateMsg;
            delete u_data.cPassword;
            console.log(u_data);
            axios({
                url: `${process.env.REACT_APP_BASE_URL}/users/add-user`,
                method: 'post',
                headers: {
                    'x-api-key': localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                data: { ...u_data }
            }).then(resp => {
                console.log(resp);
                let msg = resp.data.msg;
                let type = "success";
                setShowMessage({ msg, type });
                setTimeout(() => { setShowMessage(null) }, 2000)
            }).catch(err => {
                console.log(err);
                let msg = err.response.data.error;
                let type = "error";
                setShowMessage({ msg, type });
                setTimeout(() => { setShowMessage(null) }, 2000)
            })
        }
        // setUserData({ ...userData, ...u_data });

    }





    // console.clear();
    useEffect(() => {

        // if (!updateService) {
        //     try {
        //         console.log("updateService")
        //         getUsersOnChangeHandler();
        //     }
        //     catch (er) {
        //         console.log(er);
        //     }
        // }
        userData && userData.devices.forEach(item => {
            try {
                document.getElementById(`${item.device_id}_alias`).value = item.alias !== null || item.alias ? item.alias : "";
                document.getElementById(`${item.device_id}_id`).value = item.device_id;
                document.getElementById(`${item.device_id}_status`).value = item.status ? item.status : "";
            } catch (er) {
                console.log(er)
            }
        })
        const inputContainerEl = document.querySelector(".input-container");
        const textInputEl = document.querySelector("#userServicesEmail");
        const suggestionEl = document.querySelector(".suggestion-container");
        const ENTER_KEYCODE = 13;
        const TAB_KEYCODE = 9;
        const BACKSPACE_KEYCODE = 8;
        const UP_ARROW_KEYCODE = 38;
        const DOWN_ARROW_KEYCODE = 40;
        const SPACE_KEYCODE = 32;
        let suggestedWord = "";
        let suggestedWordsArray = [];
        let currentWordIndex = 0;
        let insertText = false;



        suggestionEl.addEventListener('click', () => {
            alert(suggestionEl.value);
            // textInputEl.value = e.target.value;
        })


        textInputEl.addEventListener("input", e => {

            if (wordsArray && wordsArray.length > 0) {
                console.log(wordsArray)
                if (e.data !== " ") {
                    insertText = true;
                }
                if (insertText === false) {
                    textInputEl.value = "";
                }

                let inputValue = e.target.value;
                suggestedWordsArray = filterArray(wordsArray, inputValue, true);
                console.log(suggestedWordsArray)
                suggestedWord = suggestedWordsArray[0];

                if (suggestedWord !== undefined) {
                    suggestionEl.innerHTML = suggestedWord;
                }

                if (inputValue.length === 0 || suggestedWordsArray.length === 0) {
                    suggestionEl.innerHTML = "";
                }
                if (textInputEl.value.length === 0) {
                    insertText = false;
                }
            }
        });



        textInputEl.addEventListener("keydown", e => {
            if (e.keyCode == ENTER_KEYCODE) {
                getUserInfoHandler();
            }

            if (textInputEl.value.length != 0) {
                if (e.keyCode == UP_ARROW_KEYCODE) {

                    if (currentWordIndex == 0) return;
                    currentWordIndex--;
                    if (suggestedWordsArray[currentWordIndex] !== undefined)
                        suggestionEl.innerHTML = suggestedWordsArray[currentWordIndex];
                    console.log(suggestedWordsArray[currentWordIndex])
                }

                if (e.keyCode == DOWN_ARROW_KEYCODE) {

                    if (currentWordIndex == suggestedWordsArray.length - 1) return;
                    currentWordIndex++;
                    if (suggestedWordsArray[currentWordIndex] !== undefined)
                        suggestionEl.innerHTML = suggestedWordsArray[currentWordIndex];
                    console.log(suggestedWordsArray[currentWordIndex])

                }

                if (e.keyCode == BACKSPACE_KEYCODE) {
                    currentWordIndex = 0;
                }
            }

            if (suggestedWord != undefined && suggestedWord != "") {
                if (e.keyCode == TAB_KEYCODE || e.keyCode == SPACE_KEYCODE) {
                    e.preventDefault();
                    textInputEl.value = suggestedWordsArray[currentWordIndex];
                    suggestionEl.innerHTML = "";
                }

            }
        });

        removeClassAfterAnimationCompletes(inputContainerEl, "animate");

        function removeClassAfterAnimationCompletes(el, className) {
            let elStyles = window.getComputedStyle(inputContainerEl);
            setTimeout(function () {
                el.classList.remove(className);
            }, +elStyles.animationDuration.replace("s", "") * 1000);
        }

        function filterArray(array, item, reverse = false) {
            try {
                if (reverse) {
                    return array
                        .filter(word => compareTwoStrings(word, item))
                        .sort((a, b) => a.length - b.length);
                } else {
                    return array.length > 0 && array
                        .filter(word => compareTwoStrings(word, item))
                        .sort((a, b) => b.length - a.length);
                }
            }
            catch (err) {
                console.log(err)
            }
        }

        function compareTwoStrings(string, subString) {
            let temp = string.split("", subString.length).join("");
            if (subString == temp) return subString;
        }
    }, [wordsArray, userData])

    return (
        <div className="justify-content-center mt-2"
            style={{
                color: "var(--theme-color)", textAlign: "left",
                margin: "40px 0"
            }}>
            {showMessage && <MessageBox type={showMessage.type} msg={showMessage.msg} />}
            <h1 style={{ marginLeft: "20px", marginBottom: "60px" }}>Services</h1>
            <Row className="mx-0">
                <Col md="12">
                    <Card heading="">
                        <Row className="mx-0">
                            <Col md="12">
                                <div className="input-container">
                                    <Input style={{ width: "100%", position: "relative" }} elementConfig={{ id: "userServicesEmail", placeholder: "Enter Email", style: { color: "#3982ff", width: "100%" }, autoComplete: "off" }} changed={getUsersOnChangeHandler} />
                                    <button className="suggestion-container"></button>
                                    <Button style={{ width: "auto", margin: "5px" }} onClick={getUserInfoHandler} >Search</Button>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row className="mx-0">
                <Col  >
                    <Card heading="Update User Data and Services">

                        <br />
                        {!addUser && <Form>
                            <Row className="mb-3">
                                <Form.Group as={Col} >
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" id="userName" placeholder="Enter Name" />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>Firm Name</Form.Label>
                                    <Form.Control type="text" id="userFirmName" placeholder="Enter Firm Name" />
                                </Form.Group>
                            </Row>

                            <Form.Group className="mb-3" >
                                <Form.Label>Address</Form.Label>
                                <Form.Control as="textarea" id="userAddr" placeholder="Enter Address" />
                            </Form.Group>
                            <Row className="mb-3">
                                <Form.Group as={Col} >
                                    <Form.Label>Contact</Form.Label>
                                    <Form.Control id="userContact" placeholder="Enter Contact Number" />
                                </Form.Group>
                                <Form.Group as={Col} >
                                    <Form.Label>Cordinates</Form.Label>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} >
                                            <Form.Control type="text" id="userLat" placeholder="Enter Latitude" />
                                        </Form.Group>
                                        <Form.Group as={Col} >
                                            <Form.Control type="text" id="userLong" placeholder="Enter Longitude" />
                                        </Form.Group>
                                    </Row>
                                </Form.Group>
                            </Row>

                            <Form.Group className="mb-3" >
                                <Row className="mb-3">
                                    <Form.Group as={Col} >
                                        <Form.Label>Email Verified</Form.Label>
                                        <Row>
                                            <Col sm={10}>
                                                <Form.Check
                                                    type="radio"
                                                    label="No"
                                                    name="emailVerified"
                                                    id="emailVerifiedNo"
                                                    value={false}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="Yes"
                                                    name="emailVerified"
                                                    id="emailVerifiedYes"
                                                    value={true}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Phone Verified</Form.Label>
                                        <Row>
                                            <Col sm={10}>
                                                <Form.Check
                                                    type="radio"
                                                    label="No"
                                                    name="phoneVerified"
                                                    id="phoneVerifiedNo"
                                                    value={false}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="Yes"
                                                    name="phoneVerified"
                                                    id="phoneVerifiedYes"
                                                    value={true}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Row>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Row className="mb-3">
                                    <Form.Label>Revoked Service</Form.Label>
                                    <Form.Group as={Col} >
                                        <Row>
                                            <Col sm={10}>
                                                <Form.Check
                                                    type="radio"
                                                    label="No"
                                                    name="revoke"
                                                    id="revokedNo"
                                                    value={false}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="Yes"
                                                    name="revoke"
                                                    id="revokeYes"
                                                    value={true}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Control id="revokeReason" type="text" placeholder="Enter Reason" />
                                    </Form.Group>
                                </Row>
                            </Form.Group>
                            <fieldset>
                                <Form.Group as={Row} className="mb-3" >
                                    <Form.Label >User Role</Form.Label>
                                    <Col sm={10}>
                                        <Form.Check
                                            type="radio"
                                            label="su"
                                            name="userRole"
                                            id="userRole_su"
                                            value="su"
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="admin"
                                            name="userRole"
                                            id="userRole_admin"
                                            value="admin"
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="client"
                                            name="userRole"
                                            id="userRole_client"
                                            value="client"
                                        />
                                    </Col>
                                </Form.Group>
                            </fieldset>
                            <hr />
                            <Button variant="primary" onClick={updateUserDataHandler}>
                                Update
                            </Button>
                        </Form>

                        }
                        {addUser && <Form>
                            <Row className="mb-3">
                                <Form.Group as={Col} >
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" id="addUserName" autoComplete="false" placeholder="Enter User Name" />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>User Email</Form.Label>
                                    <Form.Control type="email" id="addUserEmail" autoComplete="false" placeholder="Enter User Email" />
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} >
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" id="addUserPassword" autoComplete="false" placeholder="Enter User Password" />
                                </Form.Group>
                                <Form.Group as={Col} >
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control type="password" id="addUserCPassword" autoComplete="false" placeholder="Enter User Password again" />
                                </Form.Group>
                            </Row>
                            <code id="validateMsg"></code> <hr />
                            <Button variant="primary" onClick={addUserDataHandler}>
                                Add
                            </Button>
                        </Form>
                        }
                        <br />
                        <Button style={{ background: "transparent", color: "var(--theme-color)", border: "none" }} onClick={() => { setAddUser(!addUser); setTimeout(() => { !addUser && getUserInfoHandler() }, 500) }}>{!addUser ? "Do you want to add new user ?" : "Do you want to update existing user ?"}</Button>
                    </Card>
                </Col>
                {
                    userData && <Col md="auto">
                        <Card heading="Update User Devices and Services">
                            <br />
                            {userData && userData.devices.length < 1 && <>
                                <code>No records found</code>
                                <br /><br />
                            </>
                            }
                            {!updateService && <Form>
                                {userData.devices.map(device => <Row className="mb-3" key={device.device_id}>
                                    <Form.Group as={Col} >
                                        <Form.Label>Alias</Form.Label>
                                        <Form.Control type="text" placeholder="Enter Alias" id={`${device.device_id}_alias`} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Device Id</Form.Label>
                                        <Form.Control disabled type="text" id={`${device.device_id}_id`} />
                                    </Form.Group>
                                    <Form.Group as={Col} >
                                        <Form.Label>Status</Form.Label>
                                        <Form.Control disabled type="text" id={`${device.device_id}_status`} />
                                    </Form.Group>
                                </Row>
                                )}
                                {
                                    userData && addUserDev && <>
                                        <Row>
                                            <Form.Group as={Col} >
                                                <Form.Label>Device Id</Form.Label>
                                                <Form.Control type="text" id="addDevDevId" placeholder="Enter Device Id" />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label>Serial Number</Form.Label>
                                                <Form.Control type="text" id="addDevSno" placeholder="Enter Serial Number" />
                                            </Form.Group>
                                        </Row>
                                        <Button variant="orange" style={{ margin: '5px', color: "var(--text-color)", backgroundColor: "var(--theme-color)", fontSize: "10px" }} onClick={addUserDevHandler}><BiAddToQueue /> Add</Button>
                                        {/* <input id="addParaName" style={style.textBox} placeholder="Enter Device Id" />
                                <input id="addParaUnit" style={style.textBox} placeholder="Enter Serial Number" /> */}
                                    </>
                                }


                                {userData && <Button variant="dark" onClick={() => { setAddUserDev(!addUserDev) }} style={{ backgroundColor: "var(--theme-color-sec)", margin: '10px', fontSize: "0.6rem", transition: "all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)" }}>{!addUserDev ? "➕" : "❌"}</Button>}
                                <hr />

                                {userData && userData.devices.length > 0 && <Button variant="primary" onClick={updateUserDeviceHandler}>
                                    Update
                                </Button>}
                            </Form>}

                            {updateService && <Form>
                                {userData.devices.map(device => <Row className="mb-3" key={device.device_id}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Device Id</Form.Label>
                                        <Form.Control disabled value={device.device_id} type="text" id={`service_${device.device_id}_id`} />
                                    </Form.Group>
                                    <Form.Group as={Col} >
                                        <Form.Label>Service Period (yrs)</Form.Label>
                                        <Form.Control type="text" id={`service_${device.device_id}_period`} />
                                    </Form.Group>
                                    <Form.Group as={Col} style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                        <Button style={{ marginTop: "30px" }} id={`service_${device.device_id}_button`} onClick={changeServiceHandler}>Set</Button>
                                    </Form.Group>
                                </Row>)}
                            </Form>
                            }

                            <br />
                            {
                                userData && userData.devices.length > 0 &&
                                <Button style={{ background: "transparent", color: "var(--theme-color)", border: "none" }} onClick={() => { setUpdateService(!updateService); setTimeout(() => { updateService && getUserInfoHandler() }, 500) }}> {!updateService ? "Do you want to change device Service Period ?" : "Do you want to change device Alias?"} </Button>
                            }
                        </Card>
                    </Col>
                }
            </Row>
        </div >
    )
}
const mapDispatchToProps = dispatch => {
    return { checkAuthStatus: () => dispatch(actions.authCheckState()) }
}

export default connect(null, mapDispatchToProps)(Services)