import React from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import { useState } from "react"
import axios from 'axios';

import "./DeviceView.css"
import Input from "../../components/UI/Input/Input"
import Card from "../../components/Card/Card"
import MessageBox from "../../components/BasicMessageBox/MessageBox"
import CreateTable from "../../components/CreateTable/CreateTable"
import 'bootstrap/dist/css/bootstrap.min.css';



const DeviceView = () => {
    const [showMessage, setShowMessage] = useState(false);
    const [deviceData, setDeviceData] = useState(false);

    const getDeviceInfoHandler = () => {
        let value = document.getElementById('searchDeviceId').value;
        console.log(value)
        axios({
            url: `${process.env.REACT_APP_BASE_URL}/devices/getDeviceInfo/id/${value}`,
            method: 'get',
            headers: {
                'x-api-key': localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then(resp => {
            console.log(resp.data)
            setDeviceData(resp.data);
        }).catch(err => {
            let msg = err.response.data.error;
            let type = 'error';
            setShowMessage({ msg, type });
            setTimeout(() => { setShowMessage(null) }, 2000);
        })

    }
    const getDeviceDataHandler = () => {
        let value = document.getElementById('searchDeviceId').value;
        console.log(value)
        axios({
            url: `${process.env.REACT_APP_BASE_URL}/devices/getDataForAdmin/${value}/30`,
            method: 'get',
            headers: {
                'x-api-key': localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then(resp => {
            console.log(resp.data);
            setDeviceData(resp.data);
        }).catch(err => {
            let msg = err.response.data.error;
            let type = 'error';
            setShowMessage({ msg, type });
            setTimeout(() => { setShowMessage(null) }, 2000);
        })
    }


    return (
        <div className="justify-content-center mt-2"
            style={{
                color: "var(--theme-color)", textAlign: "left",
                margin: "40px 0"
            }}>
            {showMessage && <MessageBox type={showMessage.type} msg={showMessage.msg} />}
            <h1 style={{ marginLeft: "20px", marginBottom: "60px" }}>Device Data</h1>
            <Row className="mx-0">
                <Col md="auto">
                    <Card heading="">
                        <Row>
                            <Input style={{ width: "auto" }} elementConfig={{ id: "searchDeviceId", placeholder: "Enter Device Id" }} />
                            <Button style={{ width: "auto", margin: "5px" }} onClick={getDeviceInfoHandler}>Info</Button>
                            <Button style={{ width: "auto", margin: "5px" }} onClick={getDeviceDataHandler}>Data</Button>
                        </Row>

                        <br />
                        {
                            deviceData && <div style={{ height: "350px", overflow: "auto" }}>
                                <CreateTable data={deviceData} />
                            </div>
                        }
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default DeviceView