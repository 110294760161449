const CreateTable = (props) => {
    let val, uniHeader = false;


    if (Object.keys(props.data).length < 1) {
        return <code><p>Sorry! No Record Found.</p></code>
    }

    const validate = (item) => {
        try {
            // return typeof item === 'number' || typeof item === 'string' ? item :
            //     typeof item === "object" ? <CreateTable id={Object.keys(item)[1]} data={item} /> :
            //         typeof item === 'boolean' ? item.toString() :
            //             item === null ? "null" :
            //                 item.includes("data:image/jpeg;") ? <img style={{ width: "100px", height: "100px" }} src={item} /> :
            //                     typeof item === 'string' && item.length > 100 ? item.substring(0, 99) + "..." :
            //                         null

            if (typeof item === 'string') {

                if (item.includes(`data:image/jpeg;`)) {
                    return <img style={{ width: "100px", height: "100px" }} src={item} />
                }
                if (item === null) {
                    return 'null'
                }
                else return item;
            }
            else if (typeof item === 'number') {
                return item;
            }
            else if (typeof item === "object") {
                return <CreateTable id={Object.keys(item)[1]} data={item} />
            }
            else if (item === null) {
                return "null"
            }
            else if (typeof item === 'boolean') {
                return item.toString();
            }
            else {
                return 'null';
            }
        }
        catch (er) {
            //console.log(er)
        }
    }


    if (typeof props.data === 'object') {
        //console.log(props.data)
        val = props.data && Object.entries(props.data);
        if (Array.isArray(props.data)) {
            val = props.data;
        }

        return <>
            <table style={{ maxHeight: "400px", overflow: "hidden", width: "auto" }}>
                {props.data ? val.map((item, key) => {
                    if (typeof item === 'number' || typeof item === 'string') {
                        try {
                            // this is to handle array with respected indexes
                            return <tr key={item}>
                                <td key={item} style={{ backgroundColor: "var(--theme-color-dark)", color: "white" }}>{key}</td> <td>{validate(item)}</td>
                            </tr>
                        }
                        catch (err) {
                            //console.log.apply(err);
                        }
                    }
                    if (typeof item === 'object' && !Array.isArray(item)) {
                        try {
                            return <>
                                {!uniHeader &&
                                    <thead>
                                        <tr style={{ backgroundColor: "var(--theme-color-dark)", color: "white" }}>
                                            {
                                                Object.keys(item).sort((a, b) => a == "email" ? -1 : a > b ? 1 : -1
                                                ).map(val => {
                                                    //console.log(val)
                                                    uniHeader = true;
                                                    return <th key={val}>{val.replace("_", " ").toUpperCase()}</th>
                                                })
                                            }
                                        </tr>
                                    </thead>}
                                {/* <tbody> */}
                                <tr>
                                    {
                                        Object.keys(item).sort((a, b) => a == "email" ? -1 : a > b ? 1 : -1).map(val => {
                                            //console.log(item[val])
                                            try {
                                                return <td key={val}>{item[val] === null ? "null" : validate(item[val])}</td>
                                            }
                                            catch (er) {
                                                //console.log(er)
                                            }
                                        })
                                    }
                                </tr>
                                {/* </tbody> */}
                            </>
                        }
                        catch (err) {
                            ////console.log(err);
                        }
                    }
                    else {

                        try {
                            return <tr key={item}>{item.map(item => {
                                return <td key={item} style={{ width: "auto" }}>{
                                    validate(item)
                                }</td>
                            })}
                            </tr>
                        }
                        catch (er) {
                            ////console.log(er)
                        }
                    }

                }) : null}
            </table>
        </>
    }
}

export default CreateTable;