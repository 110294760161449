import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useState } from "react"
import axios from 'axios';

import "./View.css"
import Input from "../../components/UI/Input/Input"
import Card from "../../components/Card/Card"
import MessageBox from "../../components/BasicMessageBox/MessageBox"
import CreateTable from "../../components/CreateTable/CreateTable"
import 'bootstrap/dist/css/bootstrap.min.css';
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";

const View = (props) => {

    setInterval(props.checkAuthStatus, 10000);

    const style = {
        select: { backgroundColor: "var(--theme-color)", padding: "8px 10px", width: "auto", borderRadius: "5px", border: "none", marginBottom: "10px" },
        option: {
            margin: "5px",
            padding: "5px"
        },

    }

    const [userData, setUserData] = useState(null);
    const [showMessage, setShowMessage] = useState(false);

    const searchQuerryHandler = () => {
        let selectedQuerry = document.getElementById('queryType').value;
        // console.log(selectedQuerry);
        let value = document.getElementById('searchValue').value;
        // console.log(value);
        axios({
            url: `${process.env.REACT_APP_BASE_URL}/users/get-user-data/${selectedQuerry}/${value}`,
            method: 'get',
            headers: {
                'x-api-key': localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then(resp => {
            console.log(resp.data)
            setUserData(resp.data);

        }).catch(err => {
            let msg = err.response.data.error;
            let type = "error";
            // console.log(msg);
            if (msg) {
                setShowMessage({ msg, type });
                setTimeout(() => setShowMessage(null), 2000)
            }
            setUserData(null);
            props.checkAuthStatus();
        })
    }

    return (
        <div className="justify-content-center mt-2"
            style={{
                color: "var(--theme-color)", textAlign: "left",
                margin: "40px 0"
            }}>
            {showMessage && <MessageBox type={showMessage.type} msg={showMessage.msg} />}
            <h1 style={{ marginLeft: "20px", marginBottom: "60px" }}>Client Data</h1>
            <Row className="mx-0">
                <Col md="12">
                    <Card heading="">
                        <Row>
                            <select style={style.select} id="queryType" onChange={searchQuerryHandler}>
                                <option value="email">Email</option>
                                <option value="firm_name">Firm Name</option>
                                <option value="phone">Contact Number</option>
                                <option value="address">Address</option>
                                <option value="device_id">Device Id</option>
                                <option value="name">Name</option>
                            </select>
                            <Input style={{ width: "auto" }} elementConfig={{ id: "searchValue", placeholder: "Search" }} changed={searchQuerryHandler} />
                            {/* <Button style={{ width: "auto", margin: "5px" }} onClick={searchQuerryHandler}>Search</Button> */}
                        </Row>

                        <br />
                        {userData && <div style={{ height: "350px", overflow: "auto" }}>
                            <CreateTable data={userData} id={userData.email} />
                        </div>}
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return { checkAuthStatus: () => dispatch(actions.authCheckState()) }
}

export default connect(null, mapDispatchToProps)(View)